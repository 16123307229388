.writing {
  width: 87vw;
  max-width: 1600px;
  margin: auto;
  border: 2px solid var(--brdc);
  border-top: none;
  border-bottom: none;
  min-height: calc(100vh - 60px);
  background: var(--bg);
}

.left {
  min-width: 24.5%;
  width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  background: var(--light);
}
.social a{
  font-size: 24px;
  color: #000;
  transition: .2s;
}
.social a:hover{
  color: #82d303;
  transform: translateY(-3px);
}

.center {
  background: var(--light);
  border-radius: 20px;
  border: 1px solid var(--brdc);
  border-right: none;
  min-height: 810px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
}
.center::-webkit-scrollbar {
  background: transparent;
  width: 3px;
}
.center::-webkit-scrollbar-thumb {
  background: var(--brdc);
  border-radius: 20px;
}



.card {
  padding: 1rem;
  height: 150px;
  width: 100%;
}

.card .right{
  width: 250px;
}

.card:hover {
  background: #b1ee52;
}
.card:not(:first-child) {
  border-top: 1px solid var(--brdc);
}
.card > img {
  width: 170px;
  border-radius: 7px;
}

.card h2 {
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24.8px;
  letter-spacing: -0.05em;
}

.card p {
  font-size: 12px;
  font-weight: 500;
  line-height: 17.28px;
  letter-spacing: -0.05em;
  opacity: 0.6;
}

.card a {
  padding: 5px;
  border: 1px solid #00000082;
  width: 23px;
  height: 23px;
  border-radius: 8px;
}
.card a svg {
  stroke: #000;
}

.card:hover a{
  background: #000;
}
.card:hover a svg{
  stroke: #fff;
}



/* .card .content img {
  width: 23px;
  padding: 5px;
  border: 1px solid #00000082;
  border-radius: 8px;
  background: var(--light);
} */

@media (max-width:1199px) {
  .center{
    width: 54%;
  }
}

@media (max-width:992px) {
  .left,
  .center{
    width: 100%;
  }
  .center{
    border: none;
    height: fit-content;
  }
}
@media (max-width:767px) {
  .card{
    height: fit-content;
  }
  .card h2 {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width:575px) {
  .writing{
    width: 98vw;
  }
  .card{
    padding: 1rem .5rem;
  }
}
@media (max-width:500px) {
  .card{
    flex-direction: column;
    align-items: center;
  }
  .card>img{
    width: 50%;
  }
  .card .right{
    width: 100%;
  }
}
@media (max-width:400px) {
  .card>img{
    width: 100%;
  }
 
}
