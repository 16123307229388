.home {
  width: 87vw;
  max-width: 1600px;
  margin: auto;
  border: 2px solid var(--brdc);
  border-top: none;
  border-bottom: none;
  background: var(--bg);
  min-height: calc(100vh - 60px);
}

.home .left,
.home .right {
  width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  background: var(--light);
  overflow: hidden;
}

/* .home .right{
} */

.one {
  height: 155px;
}

.right .one{
  position: relative;
  z-index: 99;
}

.two,
.three {
  min-height: 310px;
  height: 40%;
  border-top: 1px solid #00000026;
  padding: 1rem;
  transition: 0.5s;
}

.two h3,
.three h3 {
  font-family: var(--monstrate);
  font-size: 22px;
  font-weight: 500;
  line-height: 25.52px;
  letter-spacing: 0.06em;
  margin-bottom: 0;
}
.three > div:first-child img,
.two > div:first-child img {
  width: 23px;
  padding: 5px;
  border: 1px solid #00000082;
  border-radius: 8px;
  background-color: #fff;
}

.three > div:first-child a,
.two > div:first-child a {
  padding: 5px;
  border: 1px solid #00000082;
  height: 23px;
  border-radius: 8px;
}
.three > div:first-child a svg,
.two > div:first-child a svg {
  stroke: #000;
}

.right p,
.left p {
  font-family: var(--mono);
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  letter-spacing: 0.06em;
  opacity: 0.54;
}

.right .one a img {
  padding: 1.5rem;
  border: 1px solid greenyellow;
  border-radius: 25px;
}

.right .three:hover,
.left .three:hover,
.left .two:hover {
  background-color: greenyellow;
}
.right .three:hover > div:first-child a,
.left .three:hover > div:first-child a,
.left .two:hover > div:first-child a {
  background: #000;
}
.right .three:hover > div:first-child a svg,
.left .three:hover > div:first-child a svg,
.left .two:hover > div:first-child a svg {
  stroke: #fff;
}
/* .three:hover> div:first-child img, 
.two:hover> div:first-child img{
  background: #000;
} */

.center {
  background-color: #e2e4f3;
  border-radius: 20px;
  border: 1px solid var(--brdc);
}

.center h1 {
  font-family: var(--mono);
  font-size: 75px;
  font-weight: 300;
  line-height: 91px;
  animation-fill-mode: none;
  -webkit-animation-fill-mode: none;
  letter-spacing: 0.06em;
  transition: 0.5s;
}
.center .title_div {
  top: 30px;
  width: 100%;
}
.center .title_div > h1:first-child {
  padding-left: 5rem;
  width: fit-content;
}
.center .title_div > h1:last-child {
  padding-right: 5rem;
  margin-top: 7rem;
  font-weight: 500;
  top: 100%;
  right: 0;
  z-index: 99;
  /* background: url(/public/assets/home.png); */
  background: url("https://img.freepik.com/free-photo/white-simple-textured-design-background_53876-106174.jpg?semt=ais_hybrid");
  background-position: center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-blend-mode: difference;
  mix-blend-mode: difference;
  transform: skew(0, 0) translate3d(0,0,0);
}

.social a{
  font-size: 24px;
  color: #000;
  transition: .2s;
}
.social a:hover{
  color: #82d303;
  transform: translateY(-3px);
}
@media (min-width: 1400px) {
  .center .title_div > h1:last-child {
    padding-right: 7rem;
  }
}

@media (min-width: 1500px) {
  .center h1 {
    font-size: 90px;
    line-height: 97px;
  }
  .center .title_div > h1:last-child {
    padding-right: 5rem;
  }
  /* .center > div:last-child {
    width: 80%;
  }
  .center > div:last-child img {
    width: 100%;
  } */
}
@media (min-width: 1700px) {
  .center h1 {
    font-size: 100px;
    line-height: 116px;
  }
  .center .title_div > h1:last-child {
    margin-top: 9.5rem;
    padding-right: 5.5rem;
  }
}

@media (max-width: 1299px) {
  .center .title_div > h1:last-child {
    padding-right: 4rem;
    margin-top: 7.5rem;
  }
}

@media (max-width: 1199px) {
  .home .left,
  .home .right {
    width: 100%;
  }
  .right .one{
    display: none;
  }
  .two,
  .three {
    min-height: 170px;
  }
  .center {
    padding: 0;
    position: relative;
  }
}

@media (max-width: 992px) {
  .center{
    padding-top: 2rem;
  }
  .center .title_div > h1:first-child {
    padding-left: 2rem;
  }
  .center .title_div > h1:last-child {
    margin-top: 17rem;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .center .title_div > h1:first-child {
    padding-left: 2rem;
  }
  .center .title_div > h1:last-child {
    margin-top: 10rem;
    padding-right: 0;
  }

  .center h1 {
    font-size: 50px;
    line-height: 66px;
  }
  .center > div:last-child {
    width: 90%;
  }
  .center > div:last-child img {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .home {
    width: 98vw;
  }
  .center .title_div h1 {
    font-size: 40px;
    padding: 0 !important;
  }
  .two,
  .three {
    min-height: fit-content;
  }
  .two > div:first-child,
  .three > div:first-child {
    margin-bottom: 1rem;
  }
}

@media (max-width: 416px) {
  .center .title_div > h1:last-child {
    margin-top: 0rem;
    padding-right: 0;
  }
  .center .title_div h1 {
    font-size: 30px;
  }
  .center {
    padding-top: 5rem;
    padding-bottom: 0rem;
  }
}
