:root{
  --bg:#C7C8D3;
  --bg2:#96EE52;
  --light: linear-gradient(178.37deg, #F3F3FF -59.77%, #E0E2F1 98.62%);
  --brdc: #d9d9d9;
  --mono: "Azeret Mono", monospace;
  --monstrate: "Montserrat", sans-serif;
}

.para{
font-size: 16px;
font-weight: 500;
line-height: 18.56px;
letter-spacing: 0.06em;
opacity: .54;

}

body{
  /* background: linear-gradient(178.37deg, #F3F3FF -59.77%, #E0E2F1 98.62%); */
  /* background: #e0e3f1; */
  background: linear-gradient(178.37deg, #F3F3FF -59.77%, #E0E2F1 98.62%);

}

button{
  color: #000;
}

.active_nav{
  position: fixed;
  left: 0;
  right: 0;
  transition: .5s;
  border-bottom: 1px solid var(--brdc) !important;
}

#object{
  display: flex;
  justify-content: center;
}
#object canvas{
  width: 580px !important;
  height: 580px !important;
  /* mix-blend-mode: luminosity; */
  filter: grayscale(1);
}
#object canvas:hover{
  /* mix-blend-mode: normal; */
  filter: none;
}
@media (min-width:1500px) {
  #object canvas{
    width: 650px !important;
    height: 650px !important;
  }
}

@media (min-width:1650px) {
  #object canvas{
    width: 800px !important;
  height: 800px !important;
  }
}

@media (max-width:1300px) {
  #object canvas{
    width: 500px !important;
  height: 500px !important;
  }
}

@media (max-width:1199px) {
  #object canvas{
    width: 800px !important;
  height: 800px !important;
  }
}

@media (max-width:992px) {
  #object canvas{
    width: 650px !important;
  height: 650px !important;
  }
}

@media (max-width:767px) {
  #object canvas{
    width: 500px !important;
  height: 500px !important;
  }
}

@media (max-width:575px) {
  #object canvas{
    width: 400px !important;
  height: 400px !important;
  }
}

@media (max-width:416px) {
  #object canvas{
    width: 300px !important;
  height: 300px !important;
  }
}