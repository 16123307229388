.right {
  min-width: 24.5%;
  width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  overflow: hidden;
  background: var(--light);
}

.right .one {
  height: 155px;
}
.right .one a img {
  padding: 1.5rem;
  border: 1px solid #96EE52;
  border-radius: 25px;
}

.right .two {
  /* padding: 1rem; */
  border-top: 1px solid var(--brdc);
  min-height: fit-content;
  height:calc(100% - 155px);
  /* padding-bottom: 4rem; */
}

.right .two h3 {
  font-family: var(--monstrate);
  font-size: 22px;
  font-weight: 500;
  line-height: 25.52px;
  letter-spacing: 0.06em;
  margin-bottom: 0;
}
.right .two > div:first-child img {
  width: 23px;
  padding: 5px;
  border: 1px solid #00000082;
  border-radius: 8px;
}
.two > div:first-child a {
  padding: 5px;
  border: 1px solid #00000082;
  height: 23px;
  border-radius: 8px;
}
.two > div:first-child a svg {
  stroke: #000;
}
.two .artical:hover {
  background-color: #B1EE52;
}
.two:hover > div:first-child a {
  background: #000;
}
.two:hover > div:first-child a svg {
  stroke: #fff;
}

.right .two > div span {
  opacity: 0.5;
  font-family: var(--mono);
  font-size: 12px;
  font-weight: 500;
  line-height: 13.92px;
  letter-spacing: 0.06em;
}

.right .two > div p {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.36px;
  letter-spacing: -0.05em;
}
@media (min-width:2500px) {
  .right .two>div:last-child {
    /* padding-bottom: 10rem; */
    justify-content: space-around !important;
  }
}

@media (max-width:1199px) {
  .right{
    width: 100%;
  }
}
@media (max-width:992px) {
  .right .one{
    display: none;
  }
}