.writing {
  width: 87vw;
  max-width: 1600px;
  margin: auto;
  border: 2px solid var(--brdc);
  border-top: none;
  border-bottom: none;
  min-height: calc(100vh - 60px);
  background: var(--bg);
}

.left {
  min-width: 24.5%;
  width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  background: var(--light);
}

.social a{
  font-size: 24px;
  color: #000;
  transition: .2s;
}
.social a:hover{
  color: #82d303;
  transform: translateY(-3px);
}

.center {
  background: var(--light);
  border-radius: 20px;
  border: 1px solid var(--brdc);
  min-height: 810px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  border-right: 0;
}
.center::-webkit-scrollbar {
  background: transparent;
  width: 3px;
}
.center::-webkit-scrollbar-thumb {
  background: var(--brdc);
  border-radius: 20px;
}
.card {
  padding: 1rem;
  height: 150px;
  width: 100%;
}
.card .imgdiv {
  min-width: 120px;
  width: 120px;
  padding: .5rem;
  height: 120px;
  background-color: #646464;
  border-radius: 5px;
}

.card:hover {
  background: #b1ee52;
}
.card {
  border-bottom: 1px solid var(--brdc);
}

.card .right{
  width: 250px;
}
.card > img {
  width: 170px;
}

.card h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.8px;
  width: calc(100% - 110px);
  letter-spacing: -0.05em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card .content .tag a,
.card span,
.card p {
  font-size: 12px;
  font-weight: 500;
  line-height: 17.28px;
  letter-spacing: -0.05em;
  opacity: 0.6;
  color: #000;
}
.card span {
  opacity: 0.8;
  line-height: 12px;
}

.card .content .tag {
  padding: 3px 10px;
  border-radius: 5px;
  background-color: #c7c8d3;
}

.card:hover .content .tag{
    background-color: #000;
    color: var(--light);
}
.card:hover .content .tag a{
    color: #fff;
    opacity: 1;
}
/* .card .content img {
  width: 23px;
  padding: 5px;
  border: 1px solid #00000082;
  border-radius: 8px;
  background: var(--light);
} */
.card .content>a {
  padding: 5px;
  border: 1px solid #00000082;
  width: 23px;
  height: 23px;
  border-radius: 8px;
}
.card .content a svg {
  stroke: #000;
}

.card:hover .content a{
  background: #000;
}
.card:hover .content a svg{
  stroke: #fff;
}

@media (max-width:1199px) {
  .center{
    width: 54%;
  }
}
@media (max-width:992px) {
  .left,
  .center{
    width: 100%;
  }
  .center{
    border: none;
    height: fit-content;
  }
  
}

@media (max-width:575px) {
  .writing{
    width: 98vw;
  }
  .card{
    padding: 1rem .5rem;
  }
}
@media (max-width:450px) {
  .card{
    flex-direction: column;
    height: fit-content;
  }
  .card .imgdiv{
    width: 100%;
  }
  .card .imgdiv img{
    height: 100%;
  }
  .card .right{
    width: 100%;
  }
}
