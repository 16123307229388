.artical {
  width: 87vw;
  max-width: 1600px;
  margin: auto;
  border: 2px solid var(--brdc);
  border-top: none;
  border-bottom: none;
  min-height: calc(100vh - 60px);
  background: var(--bg);
}

.left {
  min-width: 24.5%;
  width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  background: var(--light);
}
.social a{
  font-size: 24px;
  color: #000;
  transition: .2s;
}
.social a:hover{
  color: #82d303;
  transform: translateY(-3px);
}

.center {
  background: var(--light);
  border-radius: 20px;
  border: 1px solid var(--brdc);
  min-height: 810px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 1.5rem;
  overflow-x: hidden;
}
.center::-webkit-scrollbar {
  background: transparent;
  width: 3px;
}
.center::-webkit-scrollbar-thumb {
  background: var(--brdc);
  border-radius: 20px;
}

.center .main_img_div img{
  width: 100%;
  height: 220px;
}

.center h1{
  /* font-family: var(--mono); */
font-size: 30px;
font-weight: 500;
line-height: 46.36px;
letter-spacing: 0.05em;
}
.center li,
.center p{
font-size: 12px;
font-weight: 500;
line-height: 17.28px;
letter-spacing: -0.05em;
/* opacity: .5; */
color: rgb(0 0 0 / 50%);
}
.center li strong,
.center p strong{
  color: #000000b7;
}

@media (max-width:1199px) {
  .center{
    width: 54%;
  }
}

@media (max-width:992px) {
  .center .main_img_div img{
    height: 280px;
  }
  .left,
  .center{
    width: 100%;
  }
  .center{
    border: none;
    height: fit-content;
  }
}
@media (max-width:767px) {
  .center .main_img_div img{
    height: 220px;
  }
  .center{
    padding: 1rem;
  }
  .center h1{
    font-size: 25px;
    line-height: 35px;
  }
}
@media (max-width:575px) {
  .artical{
    width: 98vw;
  }

}
@media (max-width:500px) {
  .card{
    flex-direction: column;
    align-items: center;
  }
  .card>img{
    width: 50%;
  }
  .card .right{
    width: 100%;
  }
}
@media (max-width:450px) {
  .center .main_img_div img{
    height: auto;
  }
  .center{
    padding: 1rem .5rem;
  }
  .center h1{
    font-size: 20px;
    line-height: 30px;
  }
}
