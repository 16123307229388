.team {
  width: 87vw;
  margin: auto;
  max-width: 1600px;
  border-left: 2px solid var(--brdc);
  /* border-top: none;
  border-bottom: none; */
  min-height: calc(100vh - 60px);

  background: var(--bg);
}

.left {
  min-width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  background: var(--light);
}

.left .top li img {
  width: 23px;
  padding: 7px;
  /* border: 1px solid #00000082; */
  border-radius: 8px;
  background: var(--bg2);
  opacity: 0;
  transition: 0.5s;
}

.left .top {
  padding: 1.5rem;
}
.left .top li {
  padding: 0.4rem 0;
  border-bottom: 1px solid var(--brdc);
  cursor: pointer;
}
.left .top li span {
  opacity: 0.54;
  font-weight: 500;
}
.left .top li:hover span {
  opacity: .8;
}
.left .top li.active img,
.left .top li.active span {
  opacity: 1;
}

.social a{
  font-size: 24px;
  color: #000;
  transition: .2s;
}
.social a:hover{
  color: #82d303;
  transform: translateY(-3px);
}

.center {
  background: var(--light);
  border-radius: 20px;
  border: 1px solid var(--brdc);
  border-bottom: none;
  width: 52%;
  min-height: 810px;
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.center::-webkit-scrollbar {
  background: transparent;
  width: 3px;
}
.center::-webkit-scrollbar-thumb {
  background: var(--brdc);
  border-radius: 20px;
}

.card {
  width: 50%;
  padding: 1rem;
  height: 150px;
  border-bottom: 1px solid var(--brdc);
}
.card:hover {
  background-color: #b1ee52;
}
.card:nth-child(odd) {
  border-right: 1px solid var(--brdc);
}

.card .imgdiv {
  /* background: var(--bg); */
  background-color: #b1ee52;
  border-radius: 5px;
}
.card .imgdiv img {
  width: 110px;
  /* margin-top: -7px; */
}
.card .content h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.84px;
  letter-spacing: -0.05em;
}

.card .content p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.08px;
  letter-spacing: -0.05em;
  opacity: 0.6;
  margin-bottom: 0;
}
.card .content a img {
  width: 15px;
}
@media (max-width: 992px) {
  .left,
  .center {
    width: 100%;
  }
  .center {
    border: none;
    height: fit-content;
  }
}

@media (max-width: 767px) {
  .card {
    padding: 0.5rem;
  }
}

@media (max-width: 575px) {
  .team {
    width: 98vw;
  }
  .card {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--brdc);
  }
}
