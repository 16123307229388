.footer,
.nav {
  width: 87vw;
  max-width: 1600px;
  margin: auto;
  padding: 1rem 2rem;
  border: 2px solid var(--brdc);
  border-top: none;
  border-bottom: none;
  /* border: 1px solid #00000026 */
}

.nav {
  border-top: 4px solid var(--bg2);
  background: var(--light);
  /* transition: .5s; */
  z-index: 999;
}
.footer .logo img,
.nav .logo img {
  width: 21px;
}
.footer .logo span,
.nav .logo a {
  text-decoration: none;
  font-family: var(--monstrate);
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #000;
}

.footer .routediv a,
.nav .right li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  opacity: 0.6;
  color: #000;
  position: relative;
}

.nav .right li a:hover {
  color: #82d303;
  opacity: 1;
}
.nav .right li a::after {
  content: "";
  width: 0%;
  height: 2px;
  border-radius: 20px;
  background-color: #82d303;
  position: absolute;
  left: 50%;
  top: 110%;
  transform: translateX(-50%);
  transition: 0.5s;
}
.nav .right li a:hover::after {
  width: 100%;
}

.nav .right li.active a {
  color: #82d303;
  opacity: 1;
}

/* ====================== footer css ================ */
.footer {
  background-color: var(--bg);
}
.footer > div {
  padding: 2rem 0;
}
.footer .routediv a:hover {
  opacity: 1;
}
.footer .logo i {
  left: 20px;
  top: -5px;
  font-size: 8px;
}

.footer .rights {
  width: 500px;
}
.footer .rights p {
  font-family: var(--mono);
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  letter-spacing: 0.06em;
  opacity: 0.54;
}

.nav .right li span {
  display: none;
}

@media (max-width: 1199px) {
  .footer .rights {
    width: 410px;
  }
}

@media (max-width: 992px) {
  .footer .rights {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .right {
    position: absolute;
    top: 38px;
    left: -34px;
    height: calc(100vh - 60px);
    border: 2px solid var(--brdc);
    background: var(--light);
    width: 87vw;
    z-index: 999;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-out;
  }

  .right ul {
    border-radius: 20px;
    width: 100%;
    /* padding: 2rem 0; */
    position: relative;
  }
  .nav .right li {
    width: 100%;
    padding: 0.7rem 2rem;
    border-bottom: 1px solid var(--brdc);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav .right li span {
    padding: 5px;
    border: 1px solid #00000082;
    width: 23px;
    height: 23px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav .right li span svg {
    stroke: #000;
  }
  /* .nav .right li a {
    font-size: 16px;
  } */
  /* .right ul button{
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background-color: transparent;

  } */

  .nav .right li.active {
    background: #82d303;
    opacity: 1;
  }

  .nav .right li.active a {
    opacity: 1;
    color: #000;
  }
  .nav .right li.active span {
    background-color: #000;
    transform: rotate(90deg);
  }
  .nav .right li.active span svg {
    stroke: #fff;
  }


  .menu {
    border: none;
    background: transparent;
  }
  .menu span {
    display: block;
    width: 20px;
    height: 3px;
    background: #000;
    transition: 0.5s;
  }

  .social{
    padding: 1rem 2rem;
  }
  .social a{
    color: #000;
    font-size: 22px;
  }
}
@media (max-width: 575px) {


  .footer,
  .nav {
    width: 98vw;
  }

  .right{
    width: 98vw;
  }

  .footer > div {
    padding-bottom: 0;
    padding-top: 1rem;
  }
  .footer {
    padding: 1rem;
  }
}

@media (max-width: 416px) {
  .right {
    left: -18px;
  }
  .nav .right li {
    padding: .7rem 1rem;
  }
  .social{
    padding: 1rem;
  }
  /* .right ul {
    width: 70%;
  } */
  .nav {
    padding: 1rem;
  }
  .footer > div > div:first-child {
    flex-direction: column;
    gap: 1rem !important;
  }
}
