.about {
  width: 87vw;
  max-width: 1600px;
  margin: auto;
  border: 2px solid var(--brdc);
  border-top: none;
  border-bottom: none;
  min-height: calc(100vh - 60px);
  background: var(--bg);
}

.about .left,
.about .right {
  min-width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  background: var(--light);
}

.about .left > div {
  padding: 2rem;
}

.social a{
  font-size: 24px;
  color: #000;
  transition: .2s;
}
.social a:hover{
  color: #82d303;
  transform: translateY(-3px);
}

.right .one {
  height: 155px;
}
.right .one a img {
  padding: 1.5rem;
  border: 1px solid greenyellow;
  border-radius: 25px;
}

.right .two {
  padding: 1rem;
  border-top: 1px solid var(--brdc);
}

.right .two h3 {
  font-family: var(--monstrate);
  font-size: 22px;
  font-weight: 500;
  line-height: 25.52px;
  letter-spacing: 0.06em;
  margin-bottom: 0;
}

.right .two > div:first-child img {
  width: 23px;
  padding: 5px;
  border: 1px solid #00000082;
  border-radius: 8px;
}

.right .two > div span {
  opacity: 0.5;
  font-family: var(--mono);
  font-size: 12px;
  font-weight: 500;
  line-height: 13.92px;
  letter-spacing: 0.06em;
}

.right .two > div p {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.36px;
  letter-spacing: -0.05em;
}


.center {
    padding: 2rem;
    border: 1px solid var(--brdc);
    background: var(--light);
    border-radius: 20px;
    /* border-right: 0; */
}
.center h1{
    font-family: var(--monstrate);
font-size: 35px;
font-weight: 500;
line-height: 46.36px;
letter-spacing: 0.05em;
}
.center h5{
  opacity: 1;
  font-family: var(--monstrate);
font-weight: 600;
}
.center ul li strong{
  font-family: var(--monstrate);
}
.center ul li span{
  opacity: .54;
}
.center p{
    opacity: .54;
}


@media (max-width:767px) {
  .center h1{
    font-size: 30px;
  }
}

@media (max-width:575px) {
  .about{
    width: 98vw;
  }
  .center{
    padding: 1rem;
  }
  .center h1{
    font-size: 25px;
    line-height: 36px;
  }
}
