.artical {
  width: 87vw;
  max-width: 1600px;
  margin: auto;
  border: 2px solid var(--brdc);
  border-top: none;
  border-bottom: none;
  min-height: calc(100vh - 60px);
  background: var(--bg);
}

.left {
  min-width: 24.5%;
  width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  background: var(--light);
}

.left .top p {
  margin-bottom: 0;
  font-weight: 500;
  opacity: 0.6;
}
.left .top span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.28px;
  letter-spacing: -0.05em;
  opacity: 0.5;
}
.left .top > div:not(:first-child) {
  border-bottom: 1px solid var(--brdc);
  padding: .5rem 1rem;
}

.left .top>div:first-child{
  padding: 1rem;
  font-size: 14px;
  border-bottom: 1px solid var(--brdc);
}

.form form button[type="submit"] span,
.left .top>div:first-child span{
  padding: 5px;
  border: 1px solid #00000082;
  height: 23px;
  opacity: 1;
  border-radius: 8px;
}
.left .top>div:first-child span svg{
  stroke: #000;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.left .top>div:first-child:hover span{
  background-color: #000;
}
.left .top>div:first-child:hover span svg{
  stroke: #fff;
}
.left .top>div:first-child:hover a{
  color: #82d303 !important;
}

.social a {
  font-size: 24px;
  color: #000;
  transition: 0.2s;
}
.social a:hover {
  color: #82d303;
  transform: translateY(-3px);
}

.center {
  background: var(--light);
  border-radius: 20px;
  border: 1px solid var(--brdc);
  border-right: none;
  min-height: 810px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  /* padding: 1.5rem; */
  overflow-x: hidden;
}
.center::-webkit-scrollbar {
  background: transparent;
  width: 3px;
}
.center::-webkit-scrollbar-thumb {
  background: var(--brdc);
  border-radius: 20px;
}

.center .main_img_div img {
  width: 100%;
  height: 220px;
}

.center .navigate_div {
  border-bottom: 1px solid var(--brdc);
  padding: .95rem 1.5rem;
}
.center .navigate_div button{
  font-size: 14px;
  opacity: .6;
  font-weight: 500;
}
.center .navigate_div button span{
  padding: 5px;
  border: 1px solid #00000082;
  height: 23px;
  border-radius: 8px;
}
.center .navigate_div button span svg{
  stroke: #000;
}
.center .navigate_div button.active{
  color: #82d303;
  opacity: 1;
}
.center .navigate_div button.active span{
  background: #82d303;
  border-color: #82d303;
  transform: rotate(90deg);
}
.center .navigate_div button.active span svg{
  stroke: #fff;
}

.center .detail{
  padding: 1.5rem;
}

.center h1 {
  /* font-family: var(--mono); */
  font-size: 30px;
  font-weight: 500;
  line-height: 46.36px;
  letter-spacing: 0.05em;
}

.center li,
.center p {
  font-size: 12px;
  font-weight: 500;
  line-height: 17.28px;
  letter-spacing: -0.05em;
  opacity: 0.5;
}


.form{
  padding: 1.5rem;
}

.form div p{
  font-size: 14px;
  opacity: .7;
  padding-left: .5rem;
  margin-bottom: 7px;
}
.form div textarea,
.form div input{
  width: 47%;
  padding: .5rem 1rem;
  /* border: 1px solid var(--brdc); */
  border: 1px solid #00000038;
  border-radius: 6px;
  font-weight: 500;
  outline: none;
  font-size: 14px;
  color: #00000080;
  background: transparent;
}
.form div input::placeholder{
  /* opacity: .5; */
  color: #0000007a;
}

.form form>div{
  margin-bottom: 1.5rem;
}

.form .drager{
  height: 150px;
  border-radius: 12px;
  margin-top: 1rem;
  border: 1px dashed #00000038;
}
.form .drager button{
  background: #82d303;
  /* color: #fff; */
  border-radius: 6px;
  border: none;
  font-size: 14px;
  padding: .4rem 1rem;
}
.form .drager button input:hover{
  cursor: pointer;
}

.form form button[type="submit"]{
  border-radius: 6px;
  padding: .7rem 1rem;
  padding-left: 1.5rem;
  font-size: 14px;
  transition: .2s;
}
.form form button[type="submit"]:hover{
  background-color: #82d303 !important;
}
.form form button[type="submit"] span{
  border-color: #fff;
}
.form form button[type="submit"] span svg{
  stroke: #fff;
}

@media (max-width: 1199px) {
  .center {
    width: 54%;
  }
}

@media (max-width: 992px) {
  .center .main_img_div img {
    height: 280px;
  }
  .left,
  .center {
    width: 100%;
  }
  .center {
    border: none;
    height: fit-content;
  }
}
@media (max-width: 767px) {
  .center .main_img_div img {
    height: 220px;
  }
  .center .detail{
    padding: 1rem;
  }
  .center h1 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media (max-width: 575px) {
  .artical {
    width: 98vw;
  }
}
@media (max-width: 500px) {
  .center .navigate_div ,
  .form{
    padding: 1rem;
  }
  .form div input{
    width: 48.5%;
  }
  /* .card {
    flex-direction: column;
    align-items: center;
  } */
  /* .card > img {
    width: 50%;
  }
  .card .right {
    width: 100%;
  } */
}
@media (max-width: 450px) {
  .center .main_img_div img {
    height: auto;
  }
  .center .detail{
    padding: 1rem 0.5rem;
  }
  .center h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .drager{
    flex-direction: column;
  }
}

@media (max-width:400px) {
  .form div input{
    width: 100%;
  }
}
