.career {
  width: 87vw;
  max-width: 1600px;
  margin: auto;
  border: 2px solid var(--brdc);
  border-top: none;
  border-bottom: none;
  min-height: calc(100vh - 60px);
  background: var(--bg);
}

.left {
  min-width: 24.5%;
  width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  background: var(--light);
}
.left h3 {
  font-family: var(--monstrate);
  font-size: 22px;
  font-weight: 500;
  line-height: 25.52px;
  letter-spacing: 0.06em;
}
.left p {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.36px;
  letter-spacing: -0.05em;
  opacity: 0.8;
}
.left input{
  border: 1px solid var(--brdc);
  padding:.7rem 1rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 14px;
  border-radius: 10px;
  outline: none;
  color: gray;
  background-color: transparent;
}

.left .checkbox {
  border-radius: 3px;
  border: 1px solid #000;
  width: 13px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left .checkbox img{
  display: none;
}

.left .checkbox.active{
  background-color: #b1ee52;
}
.left .checkbox.active img{
  display: block;
  /* transform: translateY(1px); */
}

.social a{
  font-size: 24px;
  color: #000;
  transition: .2s;
}
.social a:hover{
  color: #82d303;
  transform: translateY(-3px);
}

.center {
  background: var(--light);
  border-radius: 20px;
  border: 1px solid var(--brdc);
  min-height: 810px;
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.center::-webkit-scrollbar {
  background: transparent;
  width: 3px;
}
.center::-webkit-scrollbar-thumb {
  background: var(--brdc);
  border-radius: 20px;
}

.card {
  padding: 1rem;
  /* height: 150px; */
}

.card .imgdiv {
  min-width: 60px;
  height: 60px;
  /* background-color: #646464; */
  background-color: #ffffff;
  border-radius: 5px;
  /* padding: 1rem; */
}
.card .imgdiv img {
  width: 55px;
  border-radius: 5px;
}
.card:hover {
  background: #b1ee52;
}
.card {
  border-bottom: 1px solid var(--brdc);
}
.card > img {
  width: 170px;
}

.card h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.8px;
  letter-spacing: -0.05em;
}
.card span,
.card p {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.28px;
  letter-spacing: -0.05em;
  opacity: 0.6;
}
/* 
.card .content img {
  width: 23px;
  padding: 5px;
  border: 1px solid #00000082;
  border-radius: 8px;
  background: var(--light);
} */
.card .content a>span:last-child {
  padding: 5px;
  border: 1px solid #00000082;
  width: 23px;
  height: 23px;
  border-radius: 8px;
  opacity: 1;
}
.card .content a>span:last-child svg {
  stroke: #000;
}

.card:hover .content a>span:last-child{
  background: #000;
}
.card:hover .content a>span:last-child svg{
  stroke: #fff;
}
.card .content a:hover span{
  opacity: 1;
}

@media (max-width:1199px) {
  .left{
    width: 250px;
  }
}

@media (max-width:992px) {
  .left,
  .center{
    width: 100%;
  }
  .center{
    min-height: fit-content;
    height: fit-content;
  }
}

@media (max-width:575px) {
  .career{
    width: 98vw;
  }
  .card{
    padding: 1rem .5rem;
  }
}
@media (max-width:450px) {
  .card{
    flex-direction: column;
  }
  .content{
    flex-direction: column;
    align-items: start !important;
  }

.card h2 {
  font-size: 18px;
}
}