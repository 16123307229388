.follio {
  width: 87vw;
  max-width: 1600px;
  margin: auto;
  border: 2px solid var(--brdc);
  border-top: none;
  border-bottom: none;
  min-height: calc(100vh - 60px);
  background: var(--bg);
}

.left {
  min-width: 24.5%;
  width: 24.5%;
  border-top: 1px solid var(--brdc);
  border-bottom: 1px solid var(--brdc);
  border-radius: 20px;
  background: var(--light);
}

.social a{
  font-size: 24px;
  color: #000;
  transition: .2s;
}
.social a:hover{
  color: #82d303;
  transform: translateY(-3px);
}

.center {
  background: var(--light);
  border-radius: 20px;
  border: 1px solid var(--brdc);
  border-bottom: none;
  border-right: none;
  width: 52%;
  min-height: 810px;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.center::-webkit-scrollbar{
    background: transparent;
    width: 3px;
}
.center::-webkit-scrollbar-thumb{
    background: var(--brdc);
    border-radius: 20px;
}

.card {
  width: 50%;
  padding: 1rem;
  height: 163px;
  border-bottom: 1px solid var(--brdc);
}

.card:hover{
    background-color: #B1EE52;
}
.card:nth-child(odd){
    border-right: 1px solid var(--brdc);
}

.card>div:first-child{
  min-width: 110px;
  width: 110px;
  border-radius: 5px;
  background-color: #646464;
  padding: .5rem;

}
.card img {
  width: 100%;
}

.card h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.84px;
  letter-spacing: -0.05em;
}

.card p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.08px;
  letter-spacing: -0.05em;
  opacity: 0.6;
}

/* .card a img {
  width: 23px;
  padding: 5px;
  border: 1px solid #00000082;
  border-radius: 8px;
  background: var(--light);
} */

.card a {
  padding: 5px;
  border: 1px solid #00000082;
  width: 23px;
  height: 23px;
  border-radius: 8px;
}
.card a svg {
  stroke: #000;
}

.card:hover a{
  background: #000;
}
.card:hover a svg{
  stroke: #fff;
}

@media (max-width:992px) {
  .left,
  .center{
    width: 100%;
  }
  .center{ 
    border: none;
    height: fit-content;
  }
}

@media (max-width:767px) {
  .card{
    padding: .5rem;
    height: 140px;
  }
  .card:nth-child(odd){
    border-right: none;
  }
  .card{
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--brdc);
  }
}


@media (max-width:575px) {
  .follio{
    width: 98vw;
  }
 
  .card{
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--brdc);
  }
}

@media (max-width:416px) {
  .card>div:first-child{
    min-width: 80px;
    width: 100px;
  }
}